import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { loginRequest } from "../authConfig";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

export default function Login() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <>
      <UnauthenticatedTemplate>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={6}
            md={6}
            sx={{
              backgroundImage: "url(/loginsso.png)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            display={"flex"}
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              width={"100%"}
              margin={"auto !important"}
              paddingLeft={4}
              paddingRight={4}
            >
              <img
                src="/logo.png"
                alt="Visual Comfort"
                loading="lazy"
                height={22}
                width={263}
              />
              <Typography
                align="left"
                fontSize={16}
                paddingBottom={5}
                component="p"
              >
                Payment Management
              </Typography>
              <Typography fontSize={36} component="h1" variant="h5">
                Sign in with <strong>SSO</strong>
              </Typography>
              <Box sx={{ mt: 1 }}>
                {
                  <Button
                    onClick={handleLogin}
                    type="submit"
                    id="signIn"
                    fullWidth
                    style={{
                      maxWidth: "500px",
                    }}
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    color="primary"
                  >
                    SIGN IN WITH YOUR EMAIL ID
                  </Button>
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </UnauthenticatedTemplate>
    </>
  );
}
