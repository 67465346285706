import { Box, Button, Container, Grid, Typography } from "@mui/material";

export default function PageNotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        backgroundColor: "#fff",
      }}
    >
      <Container maxWidth="md" className="filenotfound">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button href="/" variant="contained">
              Back Home
            </Button>
          </Grid>
          <Grid xs={6}>
            <img src="/404.jpg" alt="" width={500} height={250} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
