import { Routes, Route, useNavigate } from "react-router-dom";

// MSAL imports
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Types import
import { P_access } from "./utils/types";

// Sample app imports
import Home from "./pages/Home";
import Users from "./pages/Users";
import "./styles/globals.scss";
import Transactions from "./pages/Transactions";
import Refunded from "./pages/Refunded";
import Customers from "./pages/Customers";
import Configurations from "./pages/Configurations";
import PageNotFound from "./pages/404";
import { useState } from "react";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import Loader from "./ui-components/Custom-Loader";
import CustomerDetail from "./pages/Customer-Detail";
import TransactionDetail from "./pages/Transaction-Detail";
import { PageLayout } from "./ui-components/Page-Layout";
import { callMsGraph } from "./utils/MsGraphApiCall";
import RefundDetail from "./pages/Refund-Detail";
import Login from "./pages/Login";
import SecurityKeys from "./pages/Security-Keys";
import FiservHppResponse from "./pages/FiservHppResponse";
import { useLocation } from 'react-router-dom';  
import CustomerCards from "./pages/Customer-Cards";

type AppProps = {
  pca: IPublicClientApplication;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  const [loader, setLoader] = useState(false);
  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      setLoader(true);
      if (
        config.url &&
        !config.url.includes(process.env.REACT_APP_API_URL as string)
      ) {
        config.url = process.env.REACT_APP_API_URL + config.url;
      }
      const token: P_access = JSON.parse(
        window?.localStorage.getItem("paccess") || "{}"
      );
      
      const key = process.env.REACT_APP_API_KEY;
      if(key) {
        //TODO: remove Ocp-Apim-Subscription-Key when migrated to Boomi API Gateway in Production
        config.headers['x-api-key'] = key;
        //config.headers['Ocp-Apim-Subscription-Key'] = key;
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token?.accessToken}`;      
      }
      
      return config;
    },
    (err: any) => {
      setLoader(false);
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      setLoader(false);
      return response;
    },
    async (err: any) => {
      const originalRequest = err.config;
      setLoader(false);
      if (err?.response?.status && err?.response?.status === 401) {
        await callMsGraph();
        const token: P_access = JSON.parse(
          window?.localStorage.getItem("paccess") || "{}"
        );
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token?.accessToken}`;
        return axios(originalRequest);
      }
      setLoader(false);
      return Promise.reject(err);
    }
  );

  return (
    <>
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <PageLayout>
            <Pages />
          </PageLayout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <UnAuthenticationPages />
        </UnauthenticatedTemplate>
        {loader && <Loader isLoading={loader} />}
      </MsalProvider>
    </>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/users" element={<Users />} />
      
      <Route path="/transactions/captured" element={<Transactions />} />
      <Route path="/transactions/refunded" element={<Refunded />} />
      <Route
        path="/transactions/captured-detail/:id"
        element={<TransactionDetail />}
      />
      <Route
        path="/transactions/refunded-detail/:id"
        element={<RefundDetail />}
      />
      <Route path="/fiserv-hosted-page-response" element={<FiservHppResponse />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/customers/customer-detail" element={<CustomerDetail />}/>
      <Route path="/customers/customer-detail/:erpTitle/:erpId" element={<CustomerDetail />}/>
      <Route path="/configurations" element={<Configurations />} />
      <Route path="/configurations/security-keys" element={<SecurityKeys />} />      
      <Route path="/" element={<Home />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

function UnAuthenticationPages() {
  return (
    <Routes>
      <Route path="/fiserv-hosted-page-response" element={<FiservHppResponse />} />
      <Route path="*" element={<Login />} />      
    </Routes>
  );
}

export default App;
