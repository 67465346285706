import axios from "axios";
import { SecurityKeyBody } from "../utils/types";

class SecurityKeyService {
  getAllSecurityKeys = () => {
    return axios.get("PaymentConfig/keys");
  };

  generateNewKey = (data: SecurityKeyBody) => {
    return axios.post("PaymentConfig/generate", data);
  };
}

const securityKeyService = new SecurityKeyService();
export default securityKeyService;
