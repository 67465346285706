export const SVGTransactions = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72449 0C4.3551 0 0 4.47551 0 9.99796C0 15.5224 4.35306 20 9.72245 20C11.7918 20 13.6918 19.3041 15.2653 18.1694C15.6816 17.7755 15.9408 17.7286 15.9408 17.302L15.9469 17.2184C15.9469 16.7163 15.551 16.3102 15.0633 16.3102C14.8102 16.3102 14.5857 16.4204 14.4265 16.5959C13.1082 17.5878 11.4918 18.1776 9.73265 18.1776C5.33061 18.1776 1.76327 14.5102 1.76327 9.98367C1.76327 5.45714 5.33061 1.78776 9.73265 1.78776C14.1347 1.78776 17.702 5.45714 17.702 9.98367C17.702 11.2327 17.4224 12.4143 16.9347 13.4755C16.9347 13.4755 16.7245 13.8837 16.5265 14.4633L16.5204 14.5469C16.5204 15.049 16.9163 15.4571 17.4061 15.4571C17.8041 15.4571 18.1265 15.1816 18.2388 14.8102L18.2429 14.8143C19.0102 13.3857 19.4469 11.7429 19.4469 9.99592C19.449 4.47755 15.0939 0 9.72449 0ZM4.29388 8.91429C4.30612 9.11837 4.3898 9.32245 4.5449 9.47755C4.70204 9.63469 4.90408 9.71837 5.10816 9.72857C5.13878 9.73061 5.16939 9.73061 5.19796 9.72857L14.1959 9.72653C14.6714 9.72653 15.0571 9.33878 15.0571 8.86531C15.0571 8.3898 14.6735 8.00408 14.1959 8.00408L7.16531 8.01429L8.18367 6.98571C8.50816 6.66122 8.50816 6.13265 8.18367 5.80816C7.85918 5.48367 7.33061 5.48367 7.00612 5.80816L4.54286 8.29388C4.39592 8.44082 4.31633 8.62653 4.30204 8.82041L4.29592 8.82245C4.29388 8.82245 4.29184 8.88163 4.29388 8.91429ZM11.1694 14.6592C11.4939 14.9837 12.0224 14.9837 12.349 14.6592L14.8143 12.1776C14.9592 12.0306 15.0408 11.8449 15.0551 11.6531L15.0612 11.651C15.0612 11.651 15.0633 11.5898 15.0612 11.5612C15.049 11.3571 14.9653 11.1551 14.8102 11C14.6531 10.8449 14.451 10.7612 14.2469 10.749C14.2163 10.7469 14.1857 10.7469 14.1571 10.749L5.15714 10.751C4.68163 10.751 4.29592 11.1347 4.29592 11.6102C4.29592 12.0857 4.67959 12.4694 5.15714 12.4694L12.1898 12.4592L11.1714 13.4898C10.8449 13.8102 10.8449 14.3347 11.1694 14.6592Z"
        fill="#54585A"
      />
    </svg>
  );
};
