import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const FiservInteropForm = ({ requestId, visible, onClose }) => {
  const [snapPayURL, setSnapPayURL] = useState("https://stage.snappayglobal.com/Interop/InteropRequest?reqNo=");

  const dvinterop_hostedform = () => {
    if (requestId) {
      //createOverlay();
      //createIframe(requestId, snapPayURL);
      window.removeEventListener("message", messageListener);
      window.addEventListener("message", messageListener);
    } else {
      console.error("Invalid request.");
    }
  };

  const createOverlay = () => {
    const overlay = document.createElement("div");
    overlay.id = "interopform_overlay";
    Object.assign(overlay.style, {
      position: "fixed",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      width: "100%",
      height: "100%",
      border: "none",
      margin: "0",
      padding: "0",
      overflow: "hidden",
      zIndex: "999999",
      background: "rgba(0,0,0,0.4)"
    });
    document.body.appendChild(overlay);
    document.body.style.overflowY = "auto";
  };

  const createIframe = (requestId, interopUrl, reference = '') => {
    const iframe = document.createElement("iframe");
    iframe.id = "interopform_iframe";
    iframe.title = "Payment Form";
    Object.assign(iframe.style, {
      position: "fixed",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      width: "100%",
      height: "100%",
      border: "none",
      margin: "0",
      padding: "0",
      overflow: "hidden",
      zIndex: "999999",
      backgroundColor: reference === 'hostedpaymentpage' ? "#FFFFFF" : "rgba(0,0,0,0.4)"
    });

    iframe.src = `${interopUrl}/Interop/InteropForm?reqId=${encodeURIComponent(requestId)}`;
    document.body.appendChild(iframe);
  };

  const messageListener = (e) => {
    if (e.data && e.data.messageAuthor === "interopform_lightbox") {
      if (e.data.err) {
        clearIframe();
        return;
      }

      if (e.data.message) {
        createReceiptIframe(e.data.message);
      }
      clearIframe();
    }
  };

  const createReceiptIframe = (message) => {
    const overlay = document.createElement("div");
    overlay.id = "receipt_overlay";
    Object.assign(overlay.style, {
      position: "fixed",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      width: "100%",
      height: "100%",
      border: "none",
      margin: "0",
      padding: "0",
      overflowY: "auto",
      zIndex: "999999",
      background: "rgba(0,0,0,0.4)"
    });
    document.body.appendChild(overlay);

    const iframe = document.createElement("iframe");
    iframe.id = "receipt_iframe";
    iframe.title = "Receipt";
    Object.assign(iframe.style, {
      position: "fixed",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      width: "100%",
      height: "100%",
      border: "none",
      margin: "0",
      padding: "0",
      overflowY: "auto",
      zIndex: "999999"
    });
    iframe.src = `${snapPayURL}/Receipt?input=${encodeURIComponent(message)}`;
    document.body.appendChild(iframe);
  };

  const clearIframe = () => {
    const iframe = document.querySelector("#interopform_iframe");
    if (iframe) {
      iframe.remove();
    }
    exitModal();
  };

  const exitModal = () => {
    const overlay = document.querySelector("#interopform_overlay");
    if (overlay) {
      overlay.remove();
    }
    document.body.style.overflowY = "initial";
  };

  const handleClose = () => {    
    onClose();
  }

  useEffect(() => {
    dvinterop_hostedform();
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <Dialog 
    open={visible} 
    onClose={handleClose}
    PaperProps={{
        style: {
          width: '100%', // Width of iframe + some padding
          height: '100%', // Height of iframe + some padding
          maxWidth: '100%'
        },
      }}
    >
        <DialogContent>
          <iframe style={{ width: "100%", height: "100%" }} src={`${snapPayURL}${requestId}`} />
        </DialogContent>
      </Dialog>
  );
};

export default FiservInteropForm;
