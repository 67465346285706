import { Grid, CardContent } from "@mui/material";
import { card } from "../../utils/types";

type paymentDetailsProps = {
  card?: card;
  paymentInstrumentId?: string;
  customerTokenId?: string;
  account?: string;
};

export default function PaymentDetails({
  card,
  paymentInstrumentId,
  customerTokenId,
  account,
}: paymentDetailsProps) {
  return (
    <CardContent className="transaction-details">
      <Grid container className="mb-2">
        <Grid item xs={12} md={12}>
          <h5>Payment Detail</h5>
        </Grid>
      </Grid>
      <Grid container>
        {customerTokenId && (
          <>
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Customer Id:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {customerTokenId}
            </Grid>
          </>
        )}
        {paymentInstrumentId && (
          <>
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Payment Instrument Id:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {paymentInstrumentId}
            </Grid>
          </>
        )}
        {account && (
          <>
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Account Number:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {account}
            </Grid>
          </>
        )}
        {card?.type && (
          <>
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Type:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {card?.type}
            </Grid>
          </>
        )}

        {card?.expirationMonth && (
          <>
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Expiration Month:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {card?.expirationMonth}
            </Grid>
          </>
        )}

        {card?.expirationYear && (
          <>
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Expiration Year:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {card?.expirationYear}
            </Grid>
          </>
        )}
      </Grid>
    </CardContent>
  );
}
