import { ApexOptions } from "apexcharts";

const options: ApexOptions = {
    colors: ["#264653", "#FC903A", "#84A98C", "#2A9D8F", "#E9C46A", "#E76F51"],
    chart: {
      type: "donut",
      width: "100%",
    },
    legend: {
      show: true,
      fontSize: "16px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 600,
      position: "bottom",
      height: 0,
      formatter: function (seriesName: string, opts: any) {
        return (
          '<span style="text-align:left; display:block;"><span style="font-family: Roboto; font-size: 20px; font-weight: 700; line-height: 23px; letter-spacing: 0px; text-align: left;">' +
          opts.w.globals.series[opts.seriesIndex] +
          '</span><br><span style="font-family: Roboto; font-size: 13px; font-weight: 400; line-height: 15px; letter-spacing: 0.10000000149011612px; text-align: left;">' +
          seriesName +
          "</span></span>"
        );
      },
      itemMargin: {
        horizontal: 10,
        vertical: 20,
      },
    },
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    stroke: { width: 0 },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "92%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "146px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              offsetY: 25,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "46px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 700,
              color: "#54585A",
              offsetY: -18,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "Total Transactions",
              fontSize: "18px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 100,
              color: "#54585A",
              formatter: function (w: any) {
                const totals = w.globals.seriesTotals;
                const result = totals.reduce(
                  (a: number, b: number) => a + b,
                  0
                );
                return result;
              },
            },
          },
        },
      },
    },
  };

  export default options;