import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function callMsGraph() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error();
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });
  const payload = parseJwt(response.accessToken);
  localStorage.setItem("paccess", JSON.stringify(response));
  return { mail: payload.email, displayName: payload.name };
}

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
