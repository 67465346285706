import { Grid, CardContent } from "@mui/material";
import { refundPaymentTransactionDetails } from "../../utils/types";
import { formatAmount, renameObjKeys } from "../../utils/helpers";
import moment from "moment";

type billToProps = {
  paymentTransactionDetails: refundPaymentTransactionDetails;
};
export default function GeneralDetails({
  paymentTransactionDetails,
}: billToProps) {
  const newKeys = {
    transactionId: "paymentCaptureId",
    refundAmount: "totalAmount",
    processorMerchantNumber: "merchantRef",
    refundSubmitTime: "paymentSubmitTime",
    refundStatus: "paymentCaptureStatus",
  };
  paymentTransactionDetails = renameObjKeys(paymentTransactionDetails, newKeys);
  return (
    <>
      {paymentTransactionDetails && (
        <CardContent className="transaction-details">
          <Grid container>
            {paymentTransactionDetails?.paymentCaptureId && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Transaction Id:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {paymentTransactionDetails?.paymentCaptureId}
                </Grid>
              </>
            )}
            {paymentTransactionDetails?.merchantRef && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Merchant:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {paymentTransactionDetails?.merchantRef}
                </Grid>
              </>
            )}
            <Grid item xs={4} md={2}>
              <span className="fw-bold">Amount:</span>
            </Grid>
            <Grid item xs={8} md={4}>
              {paymentTransactionDetails?.totalAmount && paymentTransactionDetails?.totalAmount != null &&
                formatAmount(
                  paymentTransactionDetails.totalAmount,
                  paymentTransactionDetails.currency
                )}
              {/* If Total amount not found then fetch requested amount for invalid request */}
              {paymentTransactionDetails?.requestedAmount && paymentTransactionDetails?.requestedAmount != null && paymentTransactionDetails?.totalAmount == null &&
                paymentTransactionDetails.requestedAmount
               }
            </Grid>

            {paymentTransactionDetails?.paymentSubmitTime && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Date & Time:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {paymentTransactionDetails &&
                    moment(paymentTransactionDetails?.paymentSubmitTime).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                </Grid>
              </>
            )}

            {paymentTransactionDetails?.userId && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">User:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {paymentTransactionDetails?.userId}
                </Grid>
              </>
            )}

            {paymentTransactionDetails?.applicationName && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Application Name:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {paymentTransactionDetails?.applicationName}
                </Grid>
              </>
            )}

            {paymentTransactionDetails?.cardType && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Channel:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {paymentTransactionDetails?.cardType}
                </Grid>
              </>
            )}
            {paymentTransactionDetails?.paymentCaptureStatus && (
              <>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Status:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      padding: "5px 0",
                    }}
                  >
                    <strong
                      className={paymentTransactionDetails?.paymentCaptureStatus.toLowerCase()}
                    >
                      {paymentTransactionDetails?.paymentCaptureStatus}
                    </strong>

                    {paymentTransactionDetails?.errorMessage && (
                      <p style={{ marginBottom: 0 }}>
                        {paymentTransactionDetails?.errorReason}:{" "}
                        {paymentTransactionDetails?.errorMessage}
                      </p>
                    )}
                    {paymentTransactionDetails?.errorDetail && (
                      <p style={{ marginBottom: 0 }}>
                        {paymentTransactionDetails?.errorDetail}
                      </p>
                    )}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      )}
    </>
  );
}
