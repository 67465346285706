import { Grid, CardContent } from "@mui/material";
import { billTo } from "../../utils/types";

type billToProps = {
  billTo:billTo
}
export default function BillToDetails({billTo}:billToProps) {
  return (
    <>
    {billTo && <CardContent className="transaction-details">
        <Grid container className="mb-2">
          <Grid item xs={12} md={12}>
            <h5>Bill To Detail</h5>
          </Grid>
        </Grid>
        <Grid container>
          {billTo.firstName && <><Grid item xs={4} md={2}>
            <span className="fw-bold">First Name:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.firstName}
          </Grid></>}

          {billTo.lastName && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Last Name:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.lastName}
          </Grid></>}

          {billTo.phoneNumber && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Phone Number:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.phoneNumber}
          </Grid></>}

          {billTo.address1 && <>
          <Grid item xs={4} md={2}>
            <span className="fw-bold">Address 1:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.address1}
          </Grid></>}

          {billTo.company && <>
            <Grid item xs={4} md={2}>
            <span className="fw-bold">Company:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.company}
          </Grid>
          </>}

          {billTo.locality && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Locality:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.locality}
          </Grid></>}

          {billTo.postalCode && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Postal Code:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.postalCode}
          </Grid></>}

          {billTo.country && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Country:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.country}
          </Grid></>}

          {billTo.email && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Email:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.email}
          </Grid></>}

          {billTo.administrativeArea && <><Grid item xs={4} md={2}>
            <span className="fw-bold">Administrative Area:</span>
          </Grid>
          <Grid item xs={8} md={4}>
            {billTo.administrativeArea}
          </Grid></>}
        </Grid>
      </CardContent>}
    </>
  );
}
