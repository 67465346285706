import { Card, CardHeader, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import TransactionService from "../services/transaction";
import { RefundTransaction, RefundedTransactionMaster } from "../utils/types";
import { AxiosResponse } from "axios";
import Toast from "../ui-components/Toaster";
import BillToDetails from "../ui-components/Transaction-Details/Bill-To-Detail";
import PaymentDetails from "../ui-components/Transaction-Details/Payment-Detail";
import GeneralDetails from "../ui-components/Transaction-Details/General-Details";

export default function RefundDetail() {
  const [transaction, setTransaction] = useState<
    RefundedTransactionMaster | undefined
  >();
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const navigate = useNavigate();
  const { id } = useParams();

  const getOneTransaction = useCallback(async () => {
    try {
      const res: AxiosResponse<RefundTransaction> =
        await TransactionService.getRefundedOne(id);
      setTransaction(res?.data?.data);
    } catch (error: any) {
      setToast({
        open: Date.now(),
        message: error?.message,
        type: "error",
      });
    }
  }, [id]);

  useEffect(() => {
    getOneTransaction();
  }, [getOneTransaction]);

  return (
    <>
      {transaction && (
        <Card>
          <CardHeader
            avatar={
              <Link onClick={() => navigate(-1)} to="#">
                <ArrowBackSharpIcon sx={{ color: grey[300] }} />
              </Link>
            }
            sx={{
              borderBottom: 1,
              borderBottomColor: grey[300],
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "19px",
              color: "#54585A",
            }}
            title="Transaction Detail"
          />
          {transaction?.refundPaymentTransactionDetails && (
            <GeneralDetails
              paymentTransactionDetails={
                transaction?.refundPaymentTransactionDetails
              }
            />
          )}

          {(transaction.customerDetails?.accountNumber ||
            transaction?.customerDetails?.card ||
            transaction?.refundPaymentTransactionDetails
              ?.paymentInstrumentId) && (
            <>
              <Divider />
              <PaymentDetails
                account={transaction.customerDetails?.accountNumber}
                card={transaction?.customerDetails?.card}
                paymentInstrumentId={
                  transaction?.refundPaymentTransactionDetails
                    ?.paymentInstrumentId
                }
              />
            </>
          )}

          {transaction?.customerDetails?.billTo && (
            <>
              <Divider />
              <BillToDetails billTo={transaction?.customerDetails?.billTo} />
            </>
          )}
        </Card>
      )}
      <Toast open={toast?.open} message={toast?.message} type={toast?.type} />
    </>
  );
}
