import { useCallback, useEffect, useState } from "react";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import Datatable from "../Custom-Data-Table";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import TransactionService from "../../services/transaction";
import {
  TransactionsResponse,
  Search,
  TransactionsType,
} from "../../utils/types";
import { AxiosResponse } from "axios";
import { toPascalCase } from "../../utils/helpers";

type TransactionListProps = {
  setToast:(value: any) => void,
  userName: string | null | undefined
}

export default function TransactionList(props:TransactionListProps) {
  const setToast = props.setToast;
  const userName = props.userName;
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [fieldName, setFieldName] = useState<string>("Id");
  const [rowsData, setRowsData] = useState<TransactionsType[]>([]);
  const [size, setPageSize] = useState(8);

  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "Transaction ID",
      width: 220,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={"/transactions/captured-detail/" + params.row.id}>
          {params.row.transactionId}
        </Link>
      ),
    },
    { field: "account", headerName: "Account", flex: 1, sortable: false },
    { field: "transactionAmount", headerName: "Amount", flex: 1 },
    {
      field: "transactionStatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => toPascalCase(params?.value),
      cellClassName: (params: GridCellParams) => {
        return params?.value?.toLowerCase();
      },
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      width: 70,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={"/transactions/captured-detail/" + params.row.id}>
          <InfoIcon />
        </Link>
      ),
    },
  ];

  const getAllData = useCallback(async (
    size: number,
    page: number,
    fieldName: string,
    sortOrder: string,
    search: Search
  ) => {
    try {
      const res: AxiosResponse<TransactionsResponse> =
        await TransactionService.getAllTransaction(
          size,
          page + 1,
          fieldName,
          sortOrder,
          search
        );
      if (res && res.status === 200) {
        setRowsData(res?.data?.data?.records);
        setRowCount(res?.data?.data?.paginationInfo?.totalRecords);
      } else if (res) {
        setToast({
          open: Date.now(),
          message: res?.data.message,
          type: "error",
        });
      }
    } catch (error: any) {
      setToast({
        open: Date.now(),
        message: error?.message,
        type: "error",
      });
    }
  },[setToast]);

  useEffect(() => {
    if (userName) {
      getAllData(size, page, fieldName, sortOrder, {
        ApplicationName: "ALL",
      });
    }
  }, [page, fieldName, userName, sortOrder, size, getAllData]);


  const handleSortModelChange = (sortModel: GridSortModel) => {
    setFieldName(sortModel[0]?.field);
    setSortOrder(sortModel[0]?.sort);
  };
  
  return (
    <Datatable
                rows={rowsData}
                page={page ? page : 0}
                columns={columns}
                rowCount={rowCount || 0}
                pageSize={size}
                setPageSize={setPageSize}
                onPageChange={(newPage: number) => setPage(newPage)}
                onSortModelChange={handleSortModelChange}
                setPage={setPage}
                showtoolbar={false}
              />
  );
}
