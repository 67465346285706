export const SVGMyaccount = (props: any) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox={`0 0 ${props.box} ${props.box}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0.333374C14.8113 0.333374 12.644 0.76447 10.6219 1.60205C8.59983 2.43963 6.76251 3.66728 5.21487 5.21493C2.08926 8.34053 0.333313 12.5798 0.333313 17C0.333313 21.4203 2.08926 25.6595 5.21487 28.7852C6.76251 30.3328 8.59983 31.5605 10.6219 32.398C12.644 33.2356 14.8113 33.6667 17 33.6667C21.4203 33.6667 25.6595 31.9108 28.7851 28.7852C31.9107 25.6595 33.6666 21.4203 33.6666 17C33.6666 14.8113 33.2356 12.6441 32.398 10.622C31.5604 8.59989 30.3327 6.76257 28.7851 5.21493C27.2375 3.66728 25.4001 2.43963 23.378 1.60205C21.3559 0.76447 19.1887 0.333374 17 0.333374ZM8.78331 27.4667C9.49998 25.9667 13.8666 24.5 17 24.5C20.1333 24.5 24.5 25.9667 25.2166 27.4667C22.95 29.2667 20.1 30.3334 17 30.3334C13.9 30.3334 11.05 29.2667 8.78331 27.4667ZM27.6 25.05C25.2166 22.15 19.4333 21.1667 17 21.1667C14.5666 21.1667 8.78331 22.15 6.39998 25.05C4.69998 22.8334 3.66665 20.0334 3.66665 17C3.66665 9.65004 9.64998 3.66671 17 3.66671C24.35 3.66671 30.3333 9.65004 30.3333 17C30.3333 20.0334 29.3 22.8334 27.6 25.05ZM17 7.00004C13.7666 7.00004 11.1666 9.60004 11.1666 12.8334C11.1666 16.0667 13.7666 18.6667 17 18.6667C20.2333 18.6667 22.8333 16.0667 22.8333 12.8334C22.8333 9.60004 20.2333 7.00004 17 7.00004ZM17 15.3334C16.3369 15.3334 15.7011 15.07 15.2322 14.6011C14.7634 14.1323 14.5 13.4964 14.5 12.8334C14.5 12.1703 14.7634 11.5344 15.2322 11.0656C15.7011 10.5968 16.3369 10.3334 17 10.3334C17.663 10.3334 18.2989 10.5968 18.7677 11.0656C19.2366 11.5344 19.5 12.1703 19.5 12.8334C19.5 13.4964 19.2366 14.1323 18.7677 14.6011C18.2989 15.07 17.663 15.3334 17 15.3334Z"
        fill="#54585A"
      />
    </svg>
  );
};
