import { ApexOptions } from "apexcharts";
import {months} from "../constants";


 const barChartoptions: ApexOptions = {
  chart: {
    type: "bar",
    width: "100%",
    height: "100",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "50%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: "Overview",
    align: "left",
  },
  stroke: {
    show: true,
    width: 0,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [...months],
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val.toString();
      },
    },
  },
  legend: {
    show: true,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: 600,
    position: "bottom",
    itemMargin: {
      horizontal: 10,
      vertical: 0,
    },
  },
  responsive: [
    {
      breakpoint: 993,
      options: {
        chart: {
          width: "550",
        },
        legend: {
          show: true,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          position: "bottom",
          width: 250,
          height: 0,
          offsetX: 80,
          itemMargin: {
            horizontal: 10,
            vertical: 10,
          },
        },
      },
    },
    {
      breakpoint: 769,
      options: {
        chart: {
          width: "330",
        },
        legend: {
          show: true,
          fontSize: "11px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          position: "bottom",
          width: 170,
          height: 0,
          offsetX: 80,
          itemMargin: {
            horizontal: 10,
            vertical: 10,
          },
        },
      },
    },
  ],
};

export  default barChartoptions;