export const SVGExcel = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.17 0.25C19.39 0.25 19.5867 0.333333 19.76 0.5C19.92 0.66 20 0.853333 20 1.08V16.92C20 17.1467 19.92 17.34 19.76 17.5C19.5867 17.6667 19.39 17.75 19.17 17.75H5.83C5.61 17.75 5.41333 17.6667 5.24 17.5C5.08 17.34 5 17.1467 5 16.92V14H0.83C0.61 14 0.413333 13.92 0.24 13.76C0.08 13.5867 0 13.39 0 13.17V4.83C0 4.61 0.08 4.41333 0.24 4.24C0.413333 4.08 0.61 4 0.83 4H5V1.08C5 0.853333 5.08 0.66 5.24 0.5C5.41333 0.333333 5.61 0.25 5.83 0.25M5 10.06L6.18 12.28H7.97L6 9.06L7.93 5.89H6.22L5.13 7.9L5.09 7.96L5.06 8.03C4.88667 7.67667 4.7 7.32 4.5 6.96C4.33333 6.60667 4.15667 6.25 3.97 5.89H2.16L4.05 9.08L2 12.28H3.78M11.88 16.5V14H6.25V16.5M11.88 12.75V9.63H10V12.75M11.88 8.38V5.25H10V8.38M11.88 4V1.5H6.25V4M18.75 16.5V14H13.13V16.5M18.75 12.75V9.63H13.13V12.75M18.75 8.38V5.25H13.13V8.38M18.75 4V1.5H13.13V4H18.75Z"
        fill="#54585A"
      />
    </svg>
  );
};
