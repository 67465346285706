import { USER_ROLES } from "../utils/constants";
import jwt_decode from "jwt-decode";

export const accessValidate = (menu: string) => {
  const userData = localStorage.getItem("puser") || "";
  if (userData) {
    const user = JSON.parse(userData);
    const access = user?.permissions?.split(",");
    if (access?.length > 0) {
      return access.includes(menu);
    }
  }
  return true;
};

export const currentUserRole = () => {
  let roles: string[] = [];
  const userData = localStorage.getItem("paccess");
  if (userData) {
    const user = JSON.parse(userData);
    const payload: any = jwt_decode(user?.accessToken);
    roles = payload?.roles;
  }
  return roles && roles.length ? roles : [USER_ROLES.USER];
};
