import { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Datatable from "../ui-components/Custom-Data-Table";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import SecurityKeyService from "../services/security-key";
import Toast from "../ui-components/Toaster";
import {
  ApplicationType,
  SecurityKey,
  SecurityKeyResponse,
} from "../utils/types";
import { AxiosResponse } from "axios";
import { grey } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

export default function SecurityKeys() {
  const [page, setPage] = useState<number>(0);
  const [application, setApplication] = useState<number>();
  const [remarks, setRemarks] = useState<string>("");
  const [error, setError] = useState<string>("");
  let refundedFilter = localStorage.getItem("refundedFilter");
  refundedFilter = refundedFilter ? JSON.parse(refundedFilter) : null;
  const [filter, setFilter] = useState<any>(
    refundedFilter || {
      SearchTerm: null,
      FromDate: null,
      ToDate: null,
      Status: "",
      Currency: "",
      ApplicationName: "ALL",
    }
  );
  const [rowsData, setRowsData] = useState<SecurityKey[]>([]);
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [size, setPageSize] = useState(25);

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setApplication(0);
    setRemarks("");
    setError("");
    setOpen(false);
  };

  const handleGenerate = async () => {
    if (application && application > 0) {
      setOpen(false);
      const result = await SecurityKeyService.generateNewKey({
        application,
        remarks,
      });
      if (result) {
        setApplication(0);
        setRemarks("");
        setError("");
        setToast({
          open: Date.now(),
          message: result.data.message,
          type: "success",
        });
        getAllData();
      }
    } else {
      setError("Application field is required");
    }
  };

  const copyToClipboard = (data: any) => {
    navigator.clipboard.writeText(data);
    setToast({
      open: Date.now(),
      message: "Key copied successfully!",
      type: "success",
    });
  };

  const columns: GridColDef[] = [
    {
      field: "application",
      headerName: "Application",
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.remarks}>
          <strong>
          {params.row.application === ApplicationType.CCDB ? "CCDB" : 
   params.row.application === ApplicationType.MAGENTO ? "MAGENTO" : "JDE"}
          </strong>
        </Tooltip>
      ),
    },
    {
      field: "publicKey",
      headerName: "Public Key",
      width: 320,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <p
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            margin: 0,
          }}
        >
          <Tooltip title={params.row.remarks}>
            <span>{`*****************************${params.row.publicKey.slice(
              -5
            )}`}</span>
          </Tooltip>
          <Tooltip title="Click to copy">
            <IconButton
              style={{ marginLeft: "auto" }}
              onClick={() => {
                copyToClipboard(params.row.publicKey);
              }}
            >
              <ContentCopyIcon style={{ width: 20 }} />
            </IconButton>
          </Tooltip>
        </p>
      ),
    },
    { field: "createdBy", headerName: "Created By", flex: 1, sortable: false },
    {
      field: "createdOn",
      headerName: "Created Date",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row.createdOn).format("MM/DD/YYYY H:mm:ss "),
    },
    { field: "updatedBy", headerName: "Updated By", flex: 1, sortable: false },
    {
      field: "updatedOn",
      headerName: "Updated Date",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row.updatedOn).format("MM/DD/YYYY H:mm:ss "),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.remarks}>
          {params.row.isActive ? <CheckCircleIcon /> : <CancelIcon />}
        </Tooltip>
      ),
      // cellClassName: (params: GridCellParams) => {
      //   return params?.value?.toLowerCase();
      // },
    },
  ];

  const handleSortModelChange = () => {};

  const getAllData = useCallback(async () => {
    try {
      const res: AxiosResponse<SecurityKeyResponse> =
        await SecurityKeyService.getAllSecurityKeys();
      if (res && res.status === 200) {
        let data = res?.data?.data;
        data = data.sort((a, b) => (a === b ? 0 : a ? -1 : 1));
        setRowsData(data);
      } else if (res) {
        setToast({
          open: Date.now(),
          message: res?.data.message,
          type: "error",
        });
      }
    } catch (error: any) {
      setToast({
        open: Date.now(),
        message: error?.message,
        type: "error",
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getAllData();
    })();
  }, [getAllData]);

  return (
    <>
      <Card>
        <CardContent className="table-cardcontent">
          <CardHeader
            sx={{
              borderBottom: 1,
              borderBottomColor: grey[300],
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "19px",
              color: "#54585A",
            }}
            action={
              <Button
                variant="contained"
                onClick={handleClickOpen}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add New Key
              </Button>
            }
            title="Security Keys"
          />
          <Datatable
            rows={rowsData}
            page={page ? page : 0}
            columns={columns}
            rowCount={rowsData?.length}
            pageSize={size}
            setPageSize={setPageSize}
            onPageChange={(newPage: number) => setPage(newPage)}
            onSortModelChange={handleSortModelChange}
            setPage={setPage}
            setSearchText={setFilter}
            searchText={filter}
            showtoolbar={false}
            pagination={false}
            showstatus={true}
            showapplication={true}
            datepicker={true}
            showMerchant={true}
            transactionType="refunded"
          />
        </CardContent>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Generate your new security key"}
        </DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <div>
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <TextField
                size="small"
                select
                error={!!error}
                id="demo-simple-select"
                value={application || ""}
                label="Application Name"
                helperText={error}
                onChange={(e) => {
                  setApplication(+e.target.value);
                  setError("");
                }}
              >
                <MenuItem value={ApplicationType.CCDB}>{"CCDB"}</MenuItem>
                <MenuItem value={ApplicationType.JDE}>{"JDE"}</MenuItem>
                <MenuItem value={ApplicationType.MAGENTO}>{"MAGENTO"}</MenuItem>
              </TextField>
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth>
              <TextField
                size="small"
                id="secret-key-input"
                value={remarks}
                label="Remarks"
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              ></TextField>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{paddingBottom:25, paddingRight:24}}>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            Discard
          </Button>
          <Button variant="contained" autoFocus onClick={handleGenerate}>
            Generate
          </Button>
        </DialogActions>
      </Dialog>
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}
