import { Card, CardHeader, CardContent, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiResponse, CustomerDetails } from "../utils/types";
import { AxiosResponse } from "axios";
import fiservService from "../services/fiserv";
import { ArrowBackSharp as ArrowBackSharpIcon } from "@mui/icons-material";
import Toast from "../ui-components/Toaster";
import { ERP } from "../utils/constants";
import CustomerCards from "./Customer-Cards";


export default function CustomerDetail() {
  const { erpTitle, erpId } = useParams();
  const { state } = useLocation();
  const [sapUserId, setSapUserId] = useState<string>(null);
  const [jdeUserId, setJdeUserId] = useState<string>(null);
  const [magentoUserId, setMagentoUserId] = useState<string>(null);
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [customerData, setCustomerData] = useState<CustomerDetails | null>(null);

  // Extract from location state if available
  const { sapUserId: stateSapUserId, jdeUserId: stateJdeUserId, magentoUserId: stateMagentoUserId } = state || {};

  useEffect(() => {
    if (erpTitle) {
      // If URL parameters are present, set the ERP title and ID
      if (ERP.includes(erpTitle.toUpperCase())) {
        if (erpTitle && erpId) {
          const erpTitleUpperCase = erpTitle.toUpperCase();

          if (erpTitleUpperCase ===  "SAP") {
            setSapUserId(erpId);
          } else if (erpTitleUpperCase === "JDE"){
            setJdeUserId(erpId);
          } else if (erpTitleUpperCase === "MAGENTO"){
            setMagentoUserId(erpId);
          }
        }
      } else {
        setToast({
          open: Date.now(),
          message: "Invalid ERP Name",
          type: "error",
        });
      }
    } else {
      // Determine erpId from State
      setSapUserId(stateSapUserId);
      setJdeUserId(stateJdeUserId);
      setMagentoUserId(stateMagentoUserId);
    }
  }, [erpTitle, erpId, stateSapUserId, stateJdeUserId, stateMagentoUserId]);


  useEffect(() =>{
    if (sapUserId || jdeUserId || magentoUserId) {
      handleGetCustomer();
    }
  }, [sapUserId, jdeUserId, magentoUserId])

  const handleGetCustomer = async () => {
    try {
      const res: AxiosResponse<ApiResponse<CustomerDetails>> = await fiservService.getSavedCustomerDetails(
        sapUserId || "0", 
        jdeUserId || "0", 
        magentoUserId || "0"
      );
      if (res?.status === 200 && res.data.isSuccess && res.data.data !== null) {
        setCustomerData(res.data?.data);
      } else {
        //set for adding new card, if user not exists
        const customer: CustomerDetails = {
          id: null, 
          firstName: null, 
          lastName: null, 
          postalCode: null,
          email: null, 
          phoneNumber: null,
          sapUserId: sapUserId,
          jdeUserId: jdeUserId,
          magentoUserId: magentoUserId
        };
        setCustomerData(customer);

        setToast({
          open: Date.now(),
          message: res.data?.message,
          type: "error",
        });
      }
    } catch (err: any) {
      setToast({
        open: Date.now(),
        message: "An error occurred while getting customer details " + err,
        type: "error",
      });
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Link to="/customers">
              <ArrowBackSharpIcon sx={{ color: grey[300] }} />
            </Link>
          }
          sx={{ borderBottom: 1, borderBottomColor: grey[300] }}
          title="Customer Detail"
        />
        <CardContent>
          {customerData?.id ? (
            <>
              <Grid container>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Name:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {customerData?.firstName} {customerData?.lastName}
                </Grid>
              </Grid>       
              <Grid container>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Email:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {customerData?.email || 'N/A'}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} md={2}>
                  <span className="fw-bold">Phone Number:</span>
                </Grid>
                <Grid item xs={8} md={4}>
                  {customerData?.phoneNumber || 'N/A'}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} md={4}>
                  <span className="fw-bold">SAP ID: </span>{customerData?.sapUserId}
                </Grid>
                <Grid item xs={4} md={4}>
                  <span className="fw-bold">JDE ID: </span>{customerData?.jdeUserId}
                </Grid>
                <Grid item xs={4} md={4}>
                  <span className="fw-bold">Magento ID: </span>{customerData?.magentoUserId}
                </Grid>
              </Grid>
            </>
          ) : 
            <> 
              <Typography variant="body1" color="textSecondary">
                Customer <strong>{erpId}</strong> not found in our system.
                <br />
                Click the Add new card button if this is the first time you are adding a card for this customer.
              </Typography>
            </>
          }
        </CardContent>
      </Card>


      {/* Customer Cards component */}
      <CustomerCards {...customerData} />


      {/* Toast for success and failure */}
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}