import Spinner  from "react-bootstrap/Spinner";

const Loader = (props: any) => {
  return (
    <>
      {props.isLoading && (
        <div className="position-fixed d-flex align-items-center justify-content-center bg-white bg-opacity-50 w-100 h-100 custom-loader tooltip opacity-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default Loader;
